
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
const listCQa =ref()
export default defineComponent({
  name: "voluntary",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      masterQaList: [],
    };
  },
  setup() {
    const route = useRoute();
    const searchQa = ref("");
    onMounted(() => {
      setCurrentPageBreadcrumbs("ข่าวสารและบทความ", []);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getQa();
    });
    const getQa = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getFaq?sizeContents=99999&enable=1&page=0&id=" +
            searchQa.value,
          {
            headers: { token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),},
          }
        )
        .then((response) => {
          // console.log(response.data.data.content);
          listCQa.value = response.data.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return {
      listCQa,
      getQa,
      searchQa,
    };
  },
  async mounted() {
    const masterQa = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getFaq?sizeContents=99999&enable=1&page=0",
      {
        headers: { token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),},
      }
    );
    this.masterQaList = masterQa.data.data.content;
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
