<template>
  <!--begin::Dashboard-->
  <div class="p-5 container">
    <div class="row">
      <div class="mb-10 search">
        <el-select
          class="w-100"
          v-model="searchQa"
          filterable
          @change="getQa()"
          autocomplete="one-time-code"
          placeholder="ค้นหาหัวข้อความช่วยเหลือ"
        >
          <el-option
            v-for="sq in masterQaList"
            :key="sq['id']"
            :value="sq['id']"
            :label="sq['title'][0]['data']"
          />
        </el-select>
      </div>
      <!--begin::Accordion-->
      <div class="accordion" id="kt_accordion_1">
        <div
          class="accordion-item mb-5"
          v-for="(item, index) in listCQa"
          :key="index"
        >
          <h2 class="accordion-header" :id="'kt_accordion_1_header_1' + index">
            <!-- index == 0 ? true : false -->
            <button
              :class="
                index == 0
                  ? 'accordion-button fs-4 fw-bold'
                  : 'accordion-button fs-4 fw-bold collapsed'
              "
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#kt_accordion_1_body_1' + index"
              aria-expanded="false"
              :aria-controls="'kt_accordion_1_body_1' + index"
            >
              <p class="text-category" style="color:#000000;font-weight: 700;">
                {{ item.title[0].data }}
              </p>
            </button>
          </h2>
          <!-- index == 0 ? 'accordion-collapse collapse show'  : 'accordion-collapse collapse -->
          <div
            :id="'kt_accordion_1_body_1' + index"
            class="accordion-collapse collapse"
            :aria-labelledby="'kt_accordion_1_header_1' + index"
            data-bs-parent="#kt_accordion_1"
          >
            <div class="accordion-body">
              <p class="text-category" style="color:#9F9F9F;font-weight: 700;">
                {{ item.description[0].data }}
              </p>
              <img
                v-if="item.imagePath"
                :src="item ? imageUrl + item.imagePath : ''"
                @error="setAltImg"
                alt=""
                class="w-100 mb-5"
                style="border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            object-fit: cover;"
              />
              <video style="width:100%" controls v-if="item.videoPath">
                <source :src="imageUrl + item.videoPath" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <!-- <div class="accordion-item">
    <h2 class="accordion-header" id="kt_accordion_1_header_2">
      <button
        class="accordion-button fs-4 fw-bold collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_accordion_1_body_2"
        aria-expanded="false"
        aria-controls="kt_accordion_1_body_2"
      >
        Accordion Item #2
      </button>
    </h2>
    <div
      id="kt_accordion_1_body_2"
      class="accordion-collapse collapse"
      aria-labelledby="kt_accordion_1_header_2"
      data-bs-parent="#kt_accordion_1"
    >
      <div class="accordion-body">...</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="kt_accordion_1_header_3">
      <button
        class="accordion-button fs-4 fw-bold collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_accordion_1_body_3"
        aria-expanded="false"
        aria-controls="kt_accordion_1_body_3"
      >
        Accordion Item #3
      </button>
    </h2>
    <div
      id="kt_accordion_1_body_3"
      class="accordion-collapse collapse"
      aria-labelledby="kt_accordion_1_header_3"
      data-bs-parent="#kt_accordion_1"
    >
      <div class="accordion-body">...</div>
    </div>
  </div> -->
      </div>
      <!--end::Accordion-->
      <!-- <div class="accordion" id="kt_accordion_1">
        <div v-for="(item, index) in listCQa" :key="index">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="kt_accordion_1_header_1">
              <button
                class="accordion-button fs-4 fw-bold"
                type="button"
                data-bs-toggle="collapse "
                data-bs-target="#kt_accordion_1_body_1"
                aria-expanded="true"
                aria-controls="#kt_accordion_1_body_1"
              >
                {{ item.title[0].data }}
              </button>
            </h2>
            <div
              id="#kt_accordion_1_body_1"
              class="accordion-collapse collapse show"
              aria-labelledby="kt_accordion_1_header_1"
              data-bs-parent="#kt_accordion_1"
            >
              <div class="accordion-body">
                {{ item.description[0].data }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
const listCQa =ref()
export default defineComponent({
  name: "voluntary",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      masterQaList: [],
    };
  },
  setup() {
    const route = useRoute();
    const searchQa = ref("");
    onMounted(() => {
      setCurrentPageBreadcrumbs("ข่าวสารและบทความ", []);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getQa();
    });
    const getQa = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getFaq?sizeContents=99999&enable=1&page=0&id=" +
            searchQa.value,
          {
            headers: { token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),},
          }
        )
        .then((response) => {
          // console.log(response.data.data.content);
          listCQa.value = response.data.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return {
      listCQa,
      getQa,
      searchQa,
    };
  },
  async mounted() {
    const masterQa = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getFaq?sizeContents=99999&enable=1&page=0",
      {
        headers: { token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),},
      }
    );
    this.masterQaList = masterQa.data.data.content;
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
</script>
<style >
.search .el-select .el-input__inner {
  height: 4.29412rem;
  border-radius: 15px;
}
.search .el-select .el-input__inner .el-input__suffix {
  right: 16px;
  -webkit-transition: all var(--el-transition-duration);
  pointer-events: none;
  top: 8px;
}
</style>
